import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import React from "react";
import NotFound from "pages/notfound";
import Login from "pages/login";
import LoginCallback from "pages/login/callback";
import CreateDevice from "pages/devices/new";
import DeviceDetail from "pages/devices/_id";
import AuthRoute from "./routes.auth";
import Layout from "components/Layout";

const PageRoute = (): React.ReactElement => {
  return (
    <Router basename="/square-service">
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route component={Login} path="/login" exact />
        <Route component={LoginCallback} path="/login/callback" exact />
        <AuthRoute component={CreateDevice} path="/device/new" exact />
        <AuthRoute component={DeviceDetail} path="/device/:id" exact />
        <Route
          render={(): React.ReactElement => {
            return (
              <Layout>
                <NotFound />
              </Layout>
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(PageRoute);
