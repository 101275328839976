import Box from "@material-ui/core/Box";
import Copyright from "./Copyright";
import { FunctionComponent } from "react";
import { Button, Container, createStyles, makeStyles } from "@material-ui/core";
import { StyledChip } from "./Base";
import { LayoutProps } from "types";

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      maxHeight: "50px",
    },
  })
);

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  isAuthenticated,
  handleLogout,
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <div>
      <Box
        sx={{ display: "flex" }}
        paddingY={4}
        paddingX={8}
        justifyContent="space-between"
        alignItems="center"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/showcare.png`}
          alt="ShowCare"
          className={classes.logo}
        />
        {isAuthenticated && handleLogout && (
          <Button size="large" onClick={handleLogout} data-testid="logout">
            <StyledChip
              label="LOGOUT"
              clickable
              color="primary"
              variant="outlined"
            />
          </Button>
        )}
      </Box>
      <Container>
        <Box>
          <Box>
            {children}
            <Copyright />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Layout;
