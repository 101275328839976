import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

const Loader = (): React.ReactElement => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress
        color="primary"
        size={30}
        data-testid="loader"
        style={{ margin: "20px 0" }}
      />
    </Box>
  );
};

export default Loader;
