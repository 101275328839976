import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import BackButton from "./BackButton";

const NotFoundComponent = ({
  handleNavigation,
  message = "Oww Snap! The resource you requested is not found",
}: {
  message?: string;
  handleNavigation?: () => void;
}): React.ReactElement => {
  return (
    <Box
      my={6}
      alignItems="center"
      flexDirection="column"
      display="flex"
      data-testid="notFound"
    >
      <Typography variant="h5" color="textSecondary" align="center">
        Not Found
      </Typography>
      <Typography variant="h5" align="center">
        <b>{message}</b>
      </Typography>
      <Box mt={5}>
        <BackButton
          data-testid="backButton"
          handleNavigation={(): void => {
            if (handleNavigation) {
              handleNavigation();
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default NotFoundComponent;
