import { AuthAction, AuthToken, AuthState } from "types";
import { LOGOUT_USER, LOGIN_USER, UPDATE_LAST_VISITED_PAGE } from "./actions";

const initialState: AuthState = {
  auth: null,
};

const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, auth: action.payload as AuthToken };
    case LOGOUT_USER:
      return {
        ...state,
        auth: null,
        lastVisitedPage: "",
      };
    case UPDATE_LAST_VISITED_PAGE:
      return { ...state, lastVisitedPage: action.payload as string };
    default:
      return state;
  }
};

export default authReducer;
