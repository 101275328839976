import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Card, Chip } from "@material-ui/core";

export const CardHeader = styled.h3`
  font-size: 18px;
  text-align: center;
`;
export const CardSubHeader = styled.h4`
  text-align: center;
  font-size: 15px;
  color: #938f8fc4;
`;
export const SubTitle = styled.h3`
  text-align: center;
  font-size: 22px;
  margin: 10px 0;
`;
export const Title = styled(SubTitle)`
  font-size: 25px;
  color: #938f8fc4;
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #000;
`;
export const StyledCard = styled(Card)`
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  padding: 0 40px 40px 40px;
`;
export const StyledCopyright = styled.small`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px;
`;
export const StyledChip = styled(Chip)`
  border-color: #048280;
  color: #048280;
  text-transform: capitalize;
`;
