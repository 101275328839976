import { StyledCopyright, StyledLink } from "./Base";

const Copyright = (): React.ReactElement => {
  return (
    <StyledCopyright>
      {"Copyright © "}
      <StyledLink to={""}>ShowCare</StyledLink>
      {new Date().getFullYear()}.
    </StyledCopyright>
  );
};

export default Copyright;
