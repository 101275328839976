import {
  AWS_OIDC_CLIENT_ID,
  OPENID_CONFIG,
  SQUARE_SERVICE_BASE_URL,
} from "../constants";
import axios from "axios";

const getAuthorizationURL = async (): Promise<string> => {
  const client_id = AWS_OIDC_CLIENT_ID;
  let authorizationEndpoint = "";

  try {
    const { data } = await axios.get(OPENID_CONFIG);
    authorizationEndpoint = data.authorization_endpoint;
  } catch (error) {
    return "";
  }

  const redirect_uri = `${SQUARE_SERVICE_BASE_URL}/login/callback`;
  return `${authorizationEndpoint}?response_type=token&scope=openid&client_id=${client_id}&redirect_uri=${redirect_uri}`;
};

export default getAuthorizationURL;
