import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  CustomRouteComponentProps,
  DeviceState,
  DeviceType,
  RootState,
} from "types";
import { useDispatch, useSelector } from "react-redux";
import DeviceDetails from "components/DeviceDetails";
import { SQUARE_SERVICE_API_URL } from "../../constants";
import { setLoading, updateDevice } from "store/devices/action.creators";
import { useParams } from "react-router-dom";
import { useInterval } from "helpers/useInterval";
import NotFound from "pages/notfound";

const DeviceDetail = ({
  axiosInstance,
}: CustomRouteComponentProps): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const deviceState: DeviceState = useSelector(
    (state: RootState) => state.deviceReducer
  );

  const [error, setError] = useState<string>("");

  useEffect(() => {
    const getDevice = async (): Promise<void> => {
      dispatch(setLoading(true));
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`${SQUARE_SERVICE_API_URL}/${params.id}`);
        setError("");
        dispatch(setLoading(false));
        dispatch(updateDevice(data));
        updateDeviceDetails(data);
      } catch (error) {
        const {
          response: {
            data: { message },
          },
        } = error as {
          response: { data: { message: string } };
        };
        dispatch(setLoading(false));
        setError(message);
      }
    };

    getDevice();
  }, []);

  const handleNavigation = (): void => {
    history.push("/device/new");
  };

  const distance =
    new Date(deviceState.device?.pairBy as string).getTime() -
    new Date().getTime();

  const [countDown, setCountDown] = useState<Record<string, number>>({
    minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((distance % (1000 * 60)) / 1000),
  });
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (countDown.minutes <= 0 && countDown.seconds <= 0) {
        clearInterval(myInterval);
        return;
      }
      if (countDown.seconds > 0) {
        setCountDown((prevState) => {
          return { ...prevState, seconds: countDown.seconds - 1 };
        });
      }
      if (countDown.seconds === 0) {
        if (countDown.minutes === 0) {
          clearInterval(myInterval);
        } else {
          setCountDown((prevState) => {
            return { ...prevState, minutes: countDown.minutes - 1 };
          });
          setCountDown((prevState) => {
            return { ...prevState, seconds: 59 };
          });
        }
      }
    }, 1000);
    return (): void => {
      clearInterval(myInterval);
    };
  }, [countDown]);

  useInterval(
    countDown.minutes > 0 || countDown.seconds > 0 ? 2000 : null,
    async () => {
      const {
        data: { data },
      } = await axiosInstance.get(`${SQUARE_SERVICE_API_URL}/${params?.id}`);
      const responseData = data as DeviceType;
      updateDeviceDetails(responseData);
      if (responseData.status === "PAIRED") {
        dispatch(
          updateDevice({
            ...data,
            status: "PAIRED",
            message: "Device has been paired",
          })
        );
        setCountDown({ minutes: 0, seconds: 0 });
      }
    }
  );

  const updateDeviceDetails = (data: DeviceType): void => {
    if (data.status !== "UNPAIRED") {
      let message = "Device has been paired";
      if (
        data.status === "EXPIRED" ||
        (countDown.minutes <= 0 && countDown.seconds <= 0)
      ) {
        message = "Session has expired";
        setCountDown({ minutes: 0, seconds: 0 });
      }
      dispatch(
        updateDevice({
          ...(data as DeviceType),
          status: data.status,
          message,
        })
      );
    }
  };

  return error === "" ? (
    <DeviceDetails
      handleNavigation={handleNavigation}
      deviceState={deviceState as DeviceState}
      countDown={countDown}
      error={error}
    />
  ) : (
    <NotFound message={error} />
  );
};

export default DeviceDetail;
