import Layout from "components/Layout";
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { LockRounded } from "@material-ui/icons";
import { StyledChip } from "components/Base";
import getAuthorizationURL from "helpers/getAuthorizationURL";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLastVisitedPage } from "store/auth/action.creators";
import { Alert } from "@material-ui/lab";

const Login = ({
  instantly = false,
}: {
  instantly?: boolean;
}): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [authorizationEndpoint, setAuthorizationEndpoint] =
    useState<string>("");

  useEffect(() => {
    const getURL = async (): Promise<void> => {
      try {
        const url = await getAuthorizationURL();
        if (url != "") {
          setAuthorizationEndpoint(url);
        } else {
          setError("An error occured, please try again");
        }
      } catch (error) {
        setError("An error occured, please try again");
      }
    };
    getURL();
  }, []);

  useEffect(() => {
    if (instantly && authorizationEndpoint !== "") {
      dispatch(updateLastVisitedPage(history.location.pathname));
      redirectForAuthentication();
    }
  }, [authorizationEndpoint]);

  const redirectForAuthentication = (): void => {
    setLoading(true);
    window.location.href = authorizationEndpoint;
  };

  return (
    <Layout isAuthenticated={false}>
      <div>
        <Typography align="center">
          Welcome to the Showcare Square Service, please click the button below
          to login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
      </div>
      <Box mt={5} display="flex" justifyContent="center">
        <Button
          type="submit"
          data-testid="submitButton"
          onClick={redirectForAuthentication}
          size="large"
          disabled={loading || authorizationEndpoint === ""}
        >
          <StyledChip
            label="LOGIN"
            clickable
            color="primary"
            icon={
              loading ? (
                <CircularProgress
                  color="primary"
                  size={15}
                  data-testid="progress"
                />
              ) : (
                <LockRounded fontSize="small" data-testid="check" />
              )
            }
            data-testid="submitChip"
            variant="outlined"
            style={{
              padding: 20,
              paddingLeft: 30,
              paddingRight: 30,
              borderRadius: 20,
            }}
            disabled={loading || authorizationEndpoint === ""}
          />
        </Button>
      </Box>
    </Layout>
  );
};

export default Login;
