import { useEffect, useRef } from "react";

export const useInterval = (
  delay: number | null,
  callback?: () => void
): void => {
  const savedCallback = useRef<() => void | undefined>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
};
