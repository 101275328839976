import { AuthAction, AuthToken } from "types";
import { LOGOUT_USER, LOGIN_USER, UPDATE_LAST_VISITED_PAGE } from "./actions";

export const userLogin = (payload: AuthToken): AuthAction => {
  return { type: LOGIN_USER, payload };
};

export const userLogout = (): AuthAction => {
  return { type: LOGOUT_USER };
};

export const updateLastVisitedPage = (payload: string): AuthAction => {
  return { type: UPDATE_LAST_VISITED_PAGE, payload };
};
