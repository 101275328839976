import { DeviceAction, DeviceState, DeviceType } from "types";
import { ADD_DEVICE, SET_LOADING, UPDATE_DEVICE } from "./actions";

const initialState: DeviceState = {
  device: null,
  loading: false,
};

const deviceReducer = (
  state: DeviceState = initialState,
  action: DeviceAction
): DeviceState => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload as boolean };
    case ADD_DEVICE:
      return { ...state, device: action.payload as DeviceType };
    case UPDATE_DEVICE:
      return {
        ...state,
        device: { ...state.device, ...(action.payload as DeviceType) },
      };
    default:
      return state;
  }
};

export default deviceReducer;
