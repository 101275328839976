import { DeviceAction, DeviceType } from "types";
import { ADD_DEVICE, SET_LOADING, UPDATE_DEVICE } from "./actions";

export const setLoading = (payload: boolean): DeviceAction => {
  return { type: SET_LOADING, payload };
};

export const addDevice = (payload: DeviceType): DeviceAction => {
  return { type: ADD_DEVICE, payload };
};

export const updateDevice = (payload: DeviceType): DeviceAction => {
  return { type: UPDATE_DEVICE, payload };
};
