import { createTheme, responsiveFontSizes } from "@material-ui/core";

let theme = createTheme({
  palette: {
    primary: {
      main: "#303F58",
      light: "#5b6a85",
      dark: "#06192f",
      contrastText: "#fff",
    },
    secondary: {
      main: "#707070",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    allVariants: {
      color: "#303F58",
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
