import PageRoute from "./routes";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

function App(): React.ReactElement {
  return (
    <Router>
      <PageRoute />
    </Router>
  );
}

declare global {
  interface Window {
    SQUARE_SERVICE_BASE_URL: string;
    OPENID_CONFIG: string;
    AWS_OIDC_CLIENT_ID: string;
  }
}

export default App;
