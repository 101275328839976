import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import BackButton from "components/BackButton";
import { DeviceDetailsProps } from "types";
import Loader from "./Loader";
import { formatDate } from "helpers/formateDate";

const DeviceDetailsContent = ({
  deviceState: { device },
  countDown,
  handleNavigation,
}: DeviceDetailsProps): React.ReactElement => {
  const deviceDetails = {
    ID: device?.id,
    "Device ID": device?.deviceId,
    Name: device?.name,
    Status: device?.status,
    "Product type": device?.productType,
    "Location ID": device?.locationId,
    "Created at": formatDate(device?.createdAt),
    "Status changed at": formatDate(device?.statusChangedAt),
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={1} md={3}></Grid>
        <Grid item sm={10} md={6}>
          <Box
            display="flex"
            mb={5}
            alignItems="center"
            justifyContent="space-between"
          >
            <BackButton handleNavigation={handleNavigation} />
            <Typography variant="h6">
              <b>Device Detail</b>
            </Typography>
          </Box>
          <Paper
            style={{
              padding: "30px",
              paddingBottom: "25px",
              margin: "20px auto",
            }}
            data-testid="pinPaper"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6">
                <b>PIN: {device?.code}</b>
              </Typography>
              {device?.status !== "PAIRED" && (
                <Typography
                  variant="subtitle1"
                  style={{ marginTop: "10px" }}
                  data-testid="pairingMessage"
                  color={
                    device?.status === "EXPIRED" ? "error" : "textSecondary"
                  }
                >
                  {device?.message}
                </Typography>
              )}
            </Box>
          </Paper>
          <Paper style={{ padding: "30px", margin: "20px auto" }}>
            {Object.keys(deviceDetails).map((detail, index) => {
              return (
                Object.values(deviceDetails)[index] && (
                  <Grid
                    key={detail}
                    container
                    alignItems="center"
                    style={{ marginBottom: "20px" }}
                  >
                    <Grid item md={4}>
                      <Typography variant="subtitle1">
                        <b>{detail}:</b>
                      </Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          {Object.values(deviceDetails)[index]}
                        </Typography>
                        {detail === "Status" &&
                          device?.status === "UNPAIRED" && (
                            <CircularProgress
                              color="primary"
                              size={15}
                              data-testid="progress"
                              style={{ marginLeft: "10px" }}
                            />
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                )
              );
            })}
            {device?.status === "UNPAIRED" && (
              <Grid
                container
                alignItems="center"
                style={{ marginBottom: "10px" }}
                data-testid="unpairedInfo"
              >
                <Grid item md={4}>
                  <Typography variant="subtitle1">
                    <b>Pair by:</b>
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography variant="body2">
                    {formatDate(device?.pairBy)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(countDown.minutes > 0 || countDown.seconds > 0) && (
              <Grid
                container
                alignItems="center"
                style={{ marginBottom: "10px" }}
                data-testid="countDownTimer"
              >
                <Grid item md={4}>
                  <Typography variant="subtitle1">
                    <b>Expires In:</b>
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography variant="body2">
                    {`${countDown.minutes}m ${countDown.seconds}s`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item sm={1} md={3}></Grid>
      </Grid>
    </>
  );
};

const DetailsSwitcher = ({
  deviceState,
  countDown,
  handleNavigation,
}: DeviceDetailsProps): React.ReactElement => {
  switch (deviceState.loading) {
    case true:
      return <Loader />;
    default:
      return (
        <DeviceDetailsContent
          deviceState={deviceState}
          countDown={countDown}
          handleNavigation={handleNavigation}
        />
      );
  }
};

const DeviceDetails = ({
  handleNavigation,
  deviceState,
  countDown,
  error,
}: DeviceDetailsProps): React.ReactElement => {
  return (
    <DetailsSwitcher
      deviceState={deviceState}
      error={error}
      handleNavigation={handleNavigation}
      countDown={countDown}
    />
  );
};

export default DeviceDetails;
