import { Route, RouteComponentProps, useHistory } from "react-router-dom";
import { AuthToken, CustomRouteProps, RootState } from "types";
import { useSelector } from "react-redux";
import Layout from "components/Layout";
import { useDispatch } from "react-redux";
import { userLogout } from "store/auth/action.creators";
import axios from "axios";
import Login from "pages/login";

const AuthRoute = ({
  component: Component,
  ...routeProps
}: CustomRouteProps): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authToken: AuthToken | null = useSelector(
    (state: RootState) => state.authReducer?.auth
  );

  const handleLogout = (): void => {
    dispatch(userLogout());
    history.push("/login");
  };

  const axiosInstance = axios.create({
    headers: { Authorization: `Bearer ${authToken?.access_token}` },
  });

  const UNAUTHORIZED = 401;
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        handleLogout();
      }
      return Promise.reject(error);
    }
  );

  return (
    <Route
      {...routeProps}
      render={(props: RouteComponentProps): React.ReactElement => {
        if (authToken) {
          return (
            <Layout isAuthenticated={true} handleLogout={handleLogout}>
              <Component {...props} axiosInstance={axiosInstance} />
            </Layout>
          );
        }
        return <Login instantly={true} />;
      }}
    />
  );
};

export default AuthRoute;
