import React from "react";
import NotFoundComponent from "components/NotFound";
import { useHistory } from "react-router";

const NotFound = ({ message }: { message?: string }): React.ReactElement => {
  const history = useHistory();
  const handleNavigation = (): void => {
    history.goBack();
  };
  return (
    <NotFoundComponent handleNavigation={handleNavigation} message={message} />
  );
};

export default NotFound;
