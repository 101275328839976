export const SQUARE_SERVICE_API_URL =
  process.env.REACT_APP_SQUARE_SERVICE_API_URL ?? "/api/square/device-codes";

export const SQUARE_SERVICE_BASE_URL =
  process.env.REACT_APP_SQUARE_SERVICE_BASE_URL ??
  window.SQUARE_SERVICE_BASE_URL ??
  "https://square.staging.showcare.io/square-service";

export const OPENID_CONFIG =
  process.env.REACT_APP_OPENID_CONFIG ??
  window.OPENID_CONFIG ??
  "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_d6OrltZYt/.well-known/openid-configuration";

export const AWS_OIDC_CLIENT_ID =
  process.env.REACT_APP_AWS_OIDC_CLIENT_ID ??
  window.AWS_OIDC_CLIENT_ID ??
  "70etdu7gs6jrvpmetbmclmdu45";
