import { PropTypes, Typography } from "@material-ui/core";
import React from "react";

const ErrorMessage = ({
  message,
  align = "center",
}: {
  align?: PropTypes.Alignment;
  message: string;
}): React.ReactElement => {
  return (
    <Typography
      data-testid="errorMessage"
      color="error"
      align={align}
      style={{ margin: "10px 0" }}
    >
      {message}
    </Typography>
  );
};

export default ErrorMessage;
