import React, { useEffect, useState } from "react";
import CreateDeviceComponent from "components/CreateDeviceForm";
import { Box, Typography } from "@material-ui/core";
import { ChangeEvent, CustomRouteComponentProps, SubmitEvent } from "types";
import { useDispatch } from "react-redux";
import { addDevice } from "store/devices/action.creators";
import { useHistory } from "react-router";
import { SQUARE_SERVICE_API_URL } from "../../constants";

const CreateDevice = ({
  axiosInstance,
}: CustomRouteComponentProps): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deviceName, setDeviceName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSubmit = async (event: SubmitEvent): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axiosInstance.post(`${SQUARE_SERVICE_API_URL}`, {
        deviceCode: {
          name: deviceName,
        },
      });
      dispatch(
        addDevice({ ...data, message: "Please enter your PIN on device" })
      );
      history.push(`/device/${data.id}`);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError("An unexpected error occured. Try again later");
      setLoading(false);
    }
  };

  const handleChange = (event: ChangeEvent): void => {
    setDeviceName(event.target.value);
  };

  useEffect(() => {
    setFormValid(deviceName != "");
    return (): void => {
      setFormValid(false);
    };
  }, [deviceName]);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" mb={5}>
        <Typography variant="h5">
          <b>Pair New Device</b>
        </Typography>
      </Box>
      <CreateDeviceComponent
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        loading={loading}
        formValid={formValid}
        deviceName={deviceName}
        error={error}
      />
    </>
  );
};

export default CreateDevice;
