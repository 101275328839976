import Layout from "components/Layout";
import React from "react";
import { Box, Button } from "@material-ui/core";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "store/auth/action.creators";
import { AuthToken, RootState } from "types";
import { StyledChip } from "components/Base";

const LoginCallback = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const lastVisitedPage: string | undefined = useSelector(
    (state: RootState) => state.authReducer?.lastVisitedPage
  );

  const urlParams: Record<string, string> = {};
  location.hash
    .slice(1)
    .split("&")
    .map((item) => {
      urlParams[item.split("=")[0]] = item.split("=")[1];
    });
  if ("access_token" in urlParams) {
    const redirectURL = lastVisitedPage ? lastVisitedPage : "/device/new";
    dispatch(userLogin(urlParams as unknown as AuthToken));
    history.push(redirectURL);
  } else {
    history.push("/login");
  }

  return (
    <Layout isAuthenticated={false}>
      <div>
        <Box mt={5} display="flex" justifyContent="center">
          <Link to="/login">
            <Button size="large">
              <StyledChip
                label="GO TO LOGIN"
                clickable
                color="primary"
                data-testid="submitChip"
                variant="outlined"
                style={{
                  padding: 20,
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRadius: 20,
                  textDecoration: "none",
                }}
              />
            </Button>
          </Link>
        </Box>
      </div>
    </Layout>
  );
};

export default LoginCallback;
