import { Button, Chip } from "@material-ui/core";
import React from "react";
import { KeyboardArrowLeft as CaretLeft } from "@material-ui/icons";
import { BackButtonProps } from "types";

const BackButton = ({
  handleNavigation,
}: BackButtonProps): React.ReactElement => {
  return (
    <Button type="button" data-testid="backButton" onClick={handleNavigation}>
      <Chip
        label="Back"
        clickable
        color="default"
        variant="outlined"
        icon={<CaretLeft fontSize="small" />}
        style={{
          textTransform: "capitalize",
        }}
      />
    </Button>
  );
};

export default BackButton;
