import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { CreateDeviceFormProps } from "types";
import { Done as CheckIcon } from "@material-ui/icons";
import ErrorMessage from "./ErrorMessage";

const CreateDeviceForm = ({
  handleSubmit,
  handleChange,
  loading,
  formValid,
  deviceName,
  error,
}: CreateDeviceFormProps): React.ReactElement => {
  const disabled = !formValid || loading;

  return (
    <Box my={6}>
      <Box mb={4}>
        <Typography
          variant="subtitle1"
          data-testid="basicInfoTitle"
          align="center"
        >
          <b>Enter Device Information</b>
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          {error !== "" && <ErrorMessage message={error} />}
          <form onSubmit={handleSubmit} data-testid="form">
            <Box mb={3}>
              <TextField
                size="small"
                fullWidth
                data-testid="deviceName"
                id="device_name"
                label="Device Name"
                variant="outlined"
                name="device_name"
                value={deviceName}
                onChange={handleChange}
              />
            </Box>
            <Box mt={5} display="flex" justifyContent="center">
              <Button
                type="submit"
                disabled={disabled}
                data-testid="submitButton"
                style={{
                  opacity: disabled ? "0.5" : "0.7",
                }}
              >
                <Chip
                  label="Request PIN"
                  clickable
                  color="primary"
                  icon={
                    loading ? (
                      <CircularProgress
                        color="primary"
                        size={15}
                        data-testid="progress"
                      />
                    ) : (
                      <CheckIcon fontSize="small" data-testid="check" />
                    )
                  }
                  data-testid="submitChip"
                  variant="outlined"
                  disabled={disabled}
                  style={{
                    borderColor: "green",
                    color: "green",
                    textTransform: "capitalize",
                  }}
                />
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateDeviceForm;
