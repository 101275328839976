import { CombinedState, combineReducers } from "redux";
import deviceReducer from "store/devices/reducer";
import { RootStateOrAny } from "react-redux";
import { AuthState, DeviceState } from "types";
import authReducer from "store/auth/reducer";

const rootReducer = combineReducers({
  deviceReducer,
  authReducer,
});

// reset the state of a redux store
const appReducer = (
  state: RootStateOrAny,
  action: { type: string }
): CombinedState<{
  deviceReducer: DeviceState;
  authReducer: AuthState;
}> => {
  return rootReducer(state, action);
};

export default appReducer;
